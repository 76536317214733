import { components } from "react-select";
import Select from "react-select";
import { InputGroup, InputGroupText } from "reactstrap";

const IconSelector = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (e: string) => void;
}) => {
  const toOption = (value: string) => {
    return {
      value: value,
      label: (
        <>
          <i className={`ni ni-${value}`} /> {value}
        </>
      ),
    };
  };
  const options = [
    "air-baloon",
    "album-2",
    "align-center",
    "align-left-2",
    "ambulance",
    "app",
    "archive-2",
    "atom",
    "badge",
    "bag-17",
    "basket",
    "bell-55",
    "bold-down",
    "bold-left",
    "bold-right",
    "bold-up",
    "bold",
    "book-bookmark",
    "books",
    "box-2",
    "briefcase-24",
    "building",
    "bulb-61",
    "bullet-list-67",
    "bus-front-12",
    "button-pause",
    "button-play",
    "button-power",
    "calendar-grid-58",
    "camera-compact",
    "caps-small",
    "cart",
    "chart-bar-32",
    "chart-pie-35",
    "chat-round",
    "check-bold",
    "circle-08",
    "cloud-download-95",
    "cloud-upload-96",
    "compass-04",
    "controller",
    "credit-card",
    "curved-next",
    "delivery-fast",
    "diamond",
    "email-83",
    "fat-add",
    "fat-delete",
    "fat-remove",
    "favourite-28",
    "folder-17",
    "glasses-2",
    "hat-3",
    "headphones",
    "html5",
    "istanbul",
    "circle-08",
    "key-25",
    "laptop",
    "like-2",
    "lock-circle-open",
    "map-big",
    "mobile-button",
    "money-coins",
    "note-03",
    "notification-70",
    "palette",
    "paper-diploma",
    "pin-3",
    "planet",
    "ruler-pencil",
    "satisfied",
    "scissors",
    "send",
    "settings-gear-65",
    "settings",
    "single-02",
    "single-copy-04",
    "sound-wave",
    "spaceship",
    "square-pin",
    "support-16",
    "tablet-button",
    "tag",
    "tie-bow",
    "time-alarm",
    "trophy",
    "tv-2",
    "umbrella-13",
    "user-run",
    "vector",
    "watch-time",
    "world",
    "zoom-split-in",
    "collection",
    "image",
    "shop",
    "ungroup",
    "world-2",
    "ui-04",
  ].map(toOption);
  return (
    <InputGroup className="mb-2">
      <InputGroupText style={{ width: "25%" }}>Icon</InputGroupText>
      <Select
        components={{
          SelectContainer: ({ children }) => (
            <div
              style={{
                padding: 0,
                border: "none",
                flexGrow: 1,
              }}
              className="form-control"
            >
              {children}
            </div>
          ),
          Control: ({ children, className, ...rest }) => (
            <components.Control {...rest}>{children}</components.Control>
          ),
        }}
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: "0 0.25rem 0.25rem 0",
            height: "100%",
            borderLeft: 0,
          }),
        }}
        value={toOption(value)}
        options={options}
        onChange={(e: any) => onChange(`ni ni-${e.value}`)}
      />
    </InputGroup>
  );
};

export default IconSelector;
