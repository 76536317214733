import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";
import IconSelector from "./IconSelector";
import VariantSelector from "./VariantSelector";

interface Props {
  value: any;
  onChange: (value: any) => void;
}
const RoadmapItemForm = ({ value, onChange }: Props) => {
  return (
    <>
      <VariantSelector
        value={value.variant}
        onChange={(variant) =>
          onChange({
            ...value,
            variant,
          })
        }
      />
      <IconSelector
        value={value.icon}
        onChange={(icon) =>
          onChange({
            ...value,
            icon,
          })
        }
      />
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Title</InputGroupText>
        <Input
          placeholder="title"
          type="text"
          value={value.title}
          onChange={(e) =>
            onChange({
              ...value,
              title: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Subtitle</InputGroupText>
        <Input
          placeholder="subtitle"
          type="text"
          value={value.subtitle}
          onChange={(e) =>
            onChange({
              ...value,
              subtitle: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Date</InputGroupText>
        <Input
          placeholder="date"
          type="text"
          value={value.date}
          onChange={(e) =>
            onChange({
              ...value,
              date: e.target.value,
            })
          }
        />
      </InputGroup>
      <FormGroup>
        <textarea
          className="form-control"
          rows={5}
          placeholder="Text"
          value={value.description}
          onChange={(e) =>
            onChange({
              ...value,
              description: e.target.value,
            })
          }
        />
      </FormGroup>
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          checked={value.isDone || false}
          className="custom-control-input"
          id=" customCheckLogin"
          type="checkbox"
          onChange={(e) =>
            onChange({
              ...value,
              isDone: e.target.checked,
            })
          }
        />
        <label className="custom-control-label" htmlFor=" customCheckLogin">
          <span>Is Done ? </span>
        </label>
      </div>
    </>
  );
};

export default RoadmapItemForm;
