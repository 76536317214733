import { PageHttp } from "data/models/page";
import { useNavigate } from "react-router-dom";

const usePage = () => {
  const navigate = useNavigate();
  const getByName = async (name: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/page/${name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      }
    );

    return await response.json();
  };

  const updatePage = async (page: PageHttp) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/page/${page.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
        body: JSON.stringify(page),
        redirect: "follow",
      }
    );

    const result = await response.json();
    if (result?.statusCode === 401) {
      navigate("/login");
      return;
    }

    return result;
  };

  return {
    getByName,
    updatePage,
  };
};

export default usePage;
