import ArgumentForm from "pages/form/ArgumentForm";
import ContactForm from "pages/form/ContactForm";
import DeleteForm from "pages/form/DeleteForm";
import DocumentForm from "pages/form/DocumentForm";
import FaqItemForm from "pages/form/FaqItemForm";
import GalleryForm from "pages/form/GalleryFrom";
import HeaderForm from "pages/form/HeaderForm";
import MaskForm from "pages/form/MaskForm";
import MemberForm from "pages/form/MemberForm";
import MetaverseForm from "pages/form/MetaverseForm";
import PageHeader from "pages/form/PageHeader";
import PressKitForm from "pages/form/PressKit";
import RoadmapForm from "pages/form/RoadmapForm";
import RoadmapItemForm from "pages/form/RoadmapItemForm";
import TeamForm from "pages/form/TeamForm";
import WhitepaperForm from "pages/form/WhitepaperForm";
import { useState } from "react";
import { Button, Modal } from "reactstrap";
export interface ModalProps {
  title: string;
  type: string;
  value: any;
  onConfirm: (value: any) => void;
}

const useModal = () => {
  const [props, setProps] = useState<ModalProps>({
    title: "",
    value: {},
    type: "header",
    onConfirm: () => undefined,
  });
  const [isOpen, setIsOpen] = useState(false);

  const getContent = () => {
    switch (props.type) {
      case "header":
        return (
          <HeaderForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "mask":
        return (
          <MaskForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "faq_item":
        return (
          <FaqItemForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "roadmap_item":
        return (
          <RoadmapItemForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "page_header":
        return (
          <PageHeader
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "document":
        return (
          <DocumentForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "press-kit":
        return (
          <PressKitForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "contact":
        return (
          <ContactForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "argument":
        return (
          <ArgumentForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "delete":
        return <DeleteForm />;
      case "gallery":
        return (
          <GalleryForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "member":
        return (
          <MemberForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "metaverse":
        return (
          <MetaverseForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "roadmap":
        return (
          <RoadmapForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "team":
        return (
          <TeamForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      case "whitepaper":
        return (
          <WhitepaperForm
            value={props.value}
            onChange={(value) => setProps({ ...props, value })}
          />
        );
      default:
        return <></>;
    }
  };

  return {
    openModal: (props: ModalProps) => {
      setProps(props);
      setIsOpen(true);
    },
    closeModal: () => setIsOpen(false),
    component: (
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {props.title}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">{getContent()}</div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            onClick={() => {
              props.onConfirm(props.value);
            }}
          >
            Confirm
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    ),
  };
};

export default useModal;
