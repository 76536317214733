import Select, { components } from "react-select";
import { Badge, InputGroup, InputGroupText } from "reactstrap";

const VariantSelector = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (e: string) => void;
}) => {
  const toOption = (value: string) => {
    return {
      value: value,
      label: (
        <>
          <Badge color={value} pill className="mr-1 badge-sm">
            {value}
          </Badge>
        </>
      ),
    };
  };
  const options = ["primary", "success", "info", "warning"].map(toOption);
  return (
    <InputGroup className="mb-2">
      <InputGroupText style={{ width: "25%" }}>Color</InputGroupText>
      <Select
        components={{
          SelectContainer: ({ children }) => (
            <div
              style={{
                padding: 0,
                border: "none",
                flexGrow: 1,
              }}
              className="form-control"
            >
              {children}
            </div>
          ),
          Control: ({ children, className, ...rest }) => (
            <components.Control {...rest}>{children}</components.Control>
          ),
        }}
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: "0 0.25rem 0.25rem 0",
            height: "100%",
            borderLeft: 0,
          }),
        }}
        value={toOption(value)}
        options={options}
        onChange={(e: any) => onChange(e.value)}
      />
    </InputGroup>
  );
};

export default VariantSelector;
