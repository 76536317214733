/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes

// reactstrap components
import Loader from "components/Loader";
import { UserContext } from "context/user.context";
import useAuth from "data/services/auth.service";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const authService = useAuth();
  const navigate = useNavigate();
  const [context, setContext] = useContext(UserContext);

  const login = async () => {
    if (!username || !password) {
      return;
    }
    setHasError(false);
    setIsLoading(true);
    const response = await authService.login(username, password);
    setIsLoading(false);

    if (response?.statusCode === 401) {
      setHasError(true);
      return;
    }
    setContext({
      ...context,
      isLogin: true,
    });
    sessionStorage.setItem("api_token", response?.access_token);
    navigate("/");
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <main>
        <section
          className="section section-shaped section-lg"
          style={{ height: "100vh" }}
        >
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Sign in with credentials</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>

                          <Input
                            placeholder="Username"
                            type="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>

                          <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                      {hasError && (
                        <p className="text-danger">Invalid Credentials</p>
                      )}

                      <div className="text-center">
                        <Button
                          className={`my-4 ${
                            (username && password && " ") || "disabled"
                          }`}
                          color="primary"
                          type="button"
                          onClick={() => login()}
                        >
                          Sign in
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Login;
