const useAuth = () => {
  const login = async (username: string, password: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        redirect: "follow",
      }
    );

    return await response.json();
  };

  return {
    login,
  };
};

export default useAuth;
