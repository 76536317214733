// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar";
import CardsFooter from "components/Footers/CardsFooter";
import Loader from "components/Loader";
import { useContext, useEffect, useState } from "react";
import EditButton from "./components/EditButton";
import { HomepageContent } from "data/models/homepage";
import DeleteButton from "./components/DeleteButton";
import AddButton from "./components/AddButton";
import { MemberContent } from "data/models/members";
import { UserContext } from "context/user.context";
import usePage from "data/services/page.service";
import useMember from "data/services/member.service";
import useLink from "./hooks/UseLink";
import Skeleton from "./Skeleton";
import { useLocation } from "react-router-dom";

const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ isEditing }] = useContext(UserContext);
  const page = usePage();
  const memberService = useMember();
  const { goTo } = useLink();

  const { hash } = useLocation();

  const [content, setContent] = useState<HomepageContent | null>(null);
  const [id, setId] = useState<number>(0);

  const [members, setMembers] = useState<MemberContent[]>([]);

  const updateAndPushHomepageContent = (content: HomepageContent) => {
    setIsLoading(true);

    setContent(content);
    page
      .updatePage({
        id,
        tag: "homepage",
        title: "homepage",
        content,
      })
      .then(() => setIsLoading(false));
  };

  const updateAndPushMembersContent = (content: MemberContent[]) => {
    setMembers(content);
  };

  useEffect(() => {
    if (!hash || !id) {
      return;
    }
    setTimeout(() => {
      const elementId = hash.replace("#", "");
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, [hash, id]);

  useEffect(() => {
    page.getByName("homepage").then(({ content, id }) => {
      setContent(content);
      setId(id);
      setIsLoading(false);
    });

    memberService.getAll().then((members) => {
      setMembers(members);
    });
  }, []);

  if (!id) {
    return <Skeleton />;
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Navbar />
      <main>
        <div className="position-relative">
          <section
            className="section section-lg section-shaped pb-250 pt-150"
            style={{
              backgroundImage: `url(${content?.header.picture.content})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {content?.header.title}{" "}
                      {isEditing && (
                        <EditButton
                          title="Update Header"
                          type="header"
                          value={content?.header}
                          onConfirm={(value) => {
                            if (!content) {
                              return;
                            }
                            updateAndPushHomepageContent({
                              ...content,
                              header: value,
                            });
                          }}
                        />
                      )}
                      <span>{content?.header.subtitle} </span>
                    </h1>
                    <p className="lead text-white">{content?.header.text} </p>
                    {content?.header.link.label && (
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href={content?.header.link.link}
                          onClick={(e) => {
                            e.preventDefault();
                            goTo(content?.header.link.link || "#");
                          }}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className={content?.header.link.icon} />
                          </span>
                          <span className="btn-inner--text">
                            {content?.header.link.label}
                          </span>
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--200">
          <Container>
            <div className="b-skeleton b-skeleton-input b-skeleton-animate-wave" />
            <Row className="justify-content-center">
              <div className="b-skeleton b-skeleton-input b-skeleton-animate-wave" />
              <Col lg="12">
                <Row className="row-grid justify-content-center">
                  {content?.arguments.map((argument, index) => (
                    <Col lg="4" key={argument.title}>
                      <Card className="card-lift--hover shadow border-0">
                        {isEditing && (
                          <CardHeader
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              padding: "0.8rem",
                              background: "white",
                            }}
                          >
                            <EditButton
                              title="Update argument"
                              type="argument"
                              value={argument}
                              onConfirm={(value) =>
                                updateAndPushHomepageContent({
                                  ...content,
                                  arguments: content?.arguments.map(
                                    (arg, i) => (i === index && value) || arg
                                  ),
                                })
                              }
                            />

                            <DeleteButton
                              onConfirm={() => {
                                updateAndPushHomepageContent({
                                  ...content,
                                  arguments: content?.arguments.filter(
                                    (argument, i) => i !== index
                                  ),
                                });
                              }}
                            />
                          </CardHeader>
                        )}
                        <CardBody className="py-5">
                          <div
                            className={`icon icon-shape icon-shape-${argument.variant} rounded-circle mb-4`}
                          >
                            <i className={argument.icon} />
                          </div>
                          <h6
                            className={`text-${argument.variant} text-uppercase`}
                          >
                            {argument.title}
                          </h6>
                          <p className="description mt-3">{argument.text}</p>
                          <div>
                            {argument.tags.map((tag, index) => (
                              <Badge
                                key={`${tag} ${index}`}
                                color={argument.variant}
                                pill
                                className="mr-1"
                              >
                                {tag}
                              </Badge>
                            ))}
                          </div>
                          {argument.link.label && (
                            <Button
                              className="mt-4"
                              color={argument.variant}
                              href={argument.link.link}
                              onClick={(e) => {
                                e.preventDefault();
                                goTo(argument.link.link || "#");
                              }}
                            >
                              {argument.link.label}
                            </Button>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                  {isEditing && (content?.arguments?.length || 5) < 3 && (
                    <AddButton
                      title="Add argument"
                      type="argument"
                      value={{
                        variant: "primary",
                        icon: "",
                        title: "",
                        text: "",
                        tags: [],
                        link: {
                          label: "",
                          link: "",
                        },
                      }}
                      onConfirm={(value) => {
                        if (!content) {
                          return;
                        }
                        updateAndPushHomepageContent({
                          ...content,
                          arguments: [...content?.arguments, value],
                        });
                      }}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pb-sm-8">
          <Container>
            <img
              alt={content?.gallery.picture.title || ""}
              className="img-fluid floating"
              style={{ opacity: 0.2 }}
              src={content?.gallery.picture.content}
            />
            <div
              className="pr-md-5"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                maxWidth: 500,
                transform: "translateX(-50%) translateY(-50%)",
              }}
            >
              <h3>
                {content?.gallery.title}{" "}
                {isEditing && (
                  <EditButton
                    title="Update gallery"
                    type="gallery"
                    value={content?.gallery}
                    onConfirm={(value) => {
                      if (!content) {
                        return;
                      }
                      updateAndPushHomepageContent({
                        ...content,
                        gallery: value,
                      });
                    }}
                  />
                )}
              </h3>
              <p>{content?.gallery.text}</p>
              {content?.gallery.link.label && (
                <Button
                  className="mt-"
                  color="warning"
                  href={content?.gallery.link.link}
                  onClick={(e) => {
                    e.preventDefault();
                    goTo(content?.gallery.link.link || "#");
                  }}
                >
                  {content?.gallery.link.label}
                </Button>
              )}
            </div>
          </Container>
        </section>
        <section className="section bg-secondary" id="community">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <Card className="bg-default shadow border-0">
                  <CardImg
                    alt={content?.metaverse.picture.title}
                    src={content?.metaverse.picture.content}
                    top
                  />
                  <blockquote className="card-blockquote">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-bg"
                      preserveAspectRatio="none"
                      viewBox="0 0 583 95"
                    >
                      <polygon
                        className="fill-default"
                        points="0,52 583,95 0,95"
                      />
                      <polygon
                        className="fill-default"
                        opacity=".2"
                        points="0,42 583,95 683,0 0,95"
                      />
                    </svg>
                    <h4 className="display-3 font-weight-bold text-white">
                      {content?.metaverse.title}{" "}
                      {isEditing && (
                        <EditButton
                          title="Update metaverse"
                          type="metaverse"
                          value={content?.metaverse}
                          onConfirm={(value) => {
                            if (!content) {
                              return;
                            }
                            updateAndPushHomepageContent({
                              ...content,
                              metaverse: value,
                            });
                          }}
                        />
                      )}
                    </h4>
                    <p className="lead text-italic text-white">
                      {content?.metaverse.text}
                    </p>
                  </blockquote>
                </Card>
              </Col>
              <Col md="6">
                <div className="pl-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                    <i className={content?.roadmap.icon} />
                  </div>
                  <h3>
                    {content?.roadmap.title}{" "}
                    {isEditing && (
                      <EditButton
                        title="Update roadmap"
                        type="roadmap"
                        value={content?.roadmap}
                        onConfirm={(value) => {
                          if (!content) {
                            return;
                          }
                          updateAndPushHomepageContent({
                            ...content,
                            roadmap: value,
                          });
                        }}
                      />
                    )}
                  </h3>
                  <p className="lead">{content?.roadmap.subtitle}</p>
                  <p>{content?.roadmap.text}</p>

                  {content?.roadmap.link.label && (
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href={content?.roadmap.link.link}
                      onClick={(e) => {
                        e.preventDefault();
                        goTo(content?.roadmap.link.link || "#");
                      }}
                    >
                      {content?.roadmap.link.label}
                    </a>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section pb-0 bg-gradient-warning" id="story">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-lg-2 ml-lg-auto" md="6">
                <div className="position-relative pl-md-5">
                  <img
                    alt={content?.whitepaper.picture.title || ""}
                    className="img-center img-fluid"
                    src={content?.whitepaper.picture.content}
                  />
                </div>
              </Col>
              <Col className="order-lg-1" lg="6">
                <div className="d-flex px-3">
                  <div>
                    <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i
                        className={`${content?.whitepaper.icon} text-primary`}
                      />
                    </div>
                  </div>
                  <div className="pl-4">
                    <h4 className="display-3 text-white">
                      {content?.whitepaper.title}{" "}
                      {isEditing && (
                        <EditButton
                          title="Update whitepaper"
                          type="whitepaper"
                          value={content?.whitepaper}
                          onConfirm={(value) => {
                            if (!content) {
                              return;
                            }
                            updateAndPushHomepageContent({
                              ...content,
                              whitepaper: value,
                            });
                          }}
                        />
                      )}
                    </h4>
                    <p className="text-white">{content?.whitepaper.subtitle}</p>
                  </div>
                </div>

                {(content?.whitepaper.link.label && (
                  <Button
                    className="mt-4 mb-8 ml-md-7"
                    color="warning"
                    href={content?.whitepaper.link.link}
                    onClick={(e) => {
                      e.preventDefault();
                      goTo(content?.whitepaper.link.link || "#");
                    }}
                  >
                    {content?.whitepaper.link.label}
                  </Button>
                )) || <div className="mt-4 mb-8 ml-md-7" />}
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <section className="section section-lg" id="team">
          <Container>
            <Row className="justify-content-center text-center mb-lg">
              <Col lg="8">
                <h2 className="display-3">
                  {content?.team.title}{" "}
                  {isEditing && (
                    <EditButton
                      title="Update team"
                      type="team"
                      value={content?.team}
                      onConfirm={(value) => {
                        if (!content) {
                          return;
                        }
                        updateAndPushHomepageContent({
                          ...content,
                          team: value,
                        });
                      }}
                    />
                  )}
                </h2>
                <p className="lead text-muted">{content?.team.subtitle}</p>
              </Col>
            </Row>
            <Row>
              {members.map((member, index) => (
                <Col
                  className="mb-5 mb-lg-0 justify-content-center"
                  lg="3"
                  md="6"
                  key={member.name}
                >
                  {isEditing && (
                    <>
                      <EditButton
                        title="Update member"
                        type="member"
                        value={member}
                        onConfirm={(value) => {
                          memberService.updateMember(value);
                          updateAndPushMembersContent(
                            members.map(
                              (member, i) => (i === index && value) || member
                            )
                          );
                        }}
                      />
                      <DeleteButton
                        onConfirm={() => {
                          memberService.deleteMember(member);
                          updateAndPushMembersContent(
                            members.filter((argument, i) => i !== index)
                          );
                        }}
                      />
                    </>
                  )}
                  <div className="px-4">
                    <img
                      alt={member.picture.title}
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={member.picture.content}
                      style={{
                        width: "200px",
                        height: "180px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">{member.name}</span>
                        <small className="h6 text-muted">{member.job}</small>
                      </h5>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          {isEditing && (
            <Container className="justify-content-center d-flex">
              <AddButton
                title="Add member"
                type="member"
                value={{
                  name: "",
                  job: "",
                  description: "",
                  picture: {
                    content: "",
                    title: "",
                  },
                }}
                onConfirm={async (value) => {
                  const newMember = await memberService.addMember(value);
                  updateAndPushMembersContent([...members, newMember]);
                }}
              />
            </Container>
          )}
        </section>

        <section
          className="section section-lg bg-gradient-secondary"
          id="press-kit"
        >
          <div className="container">
            <div className="row-grid justify-content-center row">
              <div className="text-center col-lg-8">
                <h2 className="display-3">
                  {content?.presskit?.title || "Press Kit"}
                  {isEditing && (
                    <EditButton
                      title="Update press kit"
                      type="press-kit"
                      value={content?.presskit}
                      onConfirm={(value) => {
                        if (!content) {
                          return;
                        }
                        updateAndPushHomepageContent({
                          ...content,
                          presskit: value,
                        });
                      }}
                    />
                  )}
                  <span className="text-warning">
                    {content?.presskit?.subtitle || "Documents sections"}
                  </span>
                </h2>
                <p className="lead">
                  {content?.presskit?.text ||
                    "Consult the documents provided for the media, etc... lorem ipsum"}
                </p>
                <div className="text-center">
                  <h4 className="display-4 mb-5 mt-5">
                    {content?.presskit?.links || "Available Documents"}
                  </h4>
                  <div className="justify-content-center row">
                    {(content?.documents || []).map((doc, index) => (
                      <div className="mr-4 flex-1">
                        <a
                          className="btn btn-secondary"
                          href={doc.link.content}
                          download={doc.name}
                          onClick={(e) => {
                            e.preventDefault();
                            fetch(doc.link.content)
                              .then((response) => response.blob())
                              .then((blob) => {
                                var reader = new FileReader();
                                reader.onload = function () {
                                  var link = document.createElement("a");
                                  document.body.appendChild(link); // Firefox requires the link to be in the body
                                  link.download = doc.link.title;
                                  link.href =
                                    (this.result as unknown as string) || "";
                                  link.click();
                                  document.body.removeChild(link);
                                };
                                reader.readAsDataURL(blob);
                              });
                          }}
                        >
                          <p>
                            <i className="fa fa-file-arrow-down fa-2x mb-2" />
                          </p>
                          {doc.name}
                          {isEditing && (
                            <CardHeader
                              style={{
                                justifyContent: "flex-end",
                                padding: "0.8rem",
                                background: "white",
                              }}
                            >
                              <EditButton
                                title="Update document"
                                type="document"
                                value={document}
                                onConfirm={(value) => {
                                  if (!content) {
                                    return;
                                  }

                                  updateAndPushHomepageContent({
                                    ...content,
                                    documents: (content?.documents || []).map(
                                      (arg, i) => (i === index && value) || arg
                                    ),
                                  });
                                }}
                              />

                              <DeleteButton
                                onConfirm={() => {
                                  if (!content) {
                                    return;
                                  }
                                  updateAndPushHomepageContent({
                                    ...content,
                                    documents: (
                                      content?.documents || []
                                    ).filter((argument, i) => i !== index),
                                  });
                                }}
                              />
                            </CardHeader>
                          )}
                        </a>
                      </div>
                    ))}
                  </div>
                  {isEditing && (
                    <Container className="justify-content-center d-flex">
                      <AddButton
                        title="Add document"
                        type="document"
                        value={{
                          name: "",
                          link: "",
                        }}
                        onConfirm={async (value) => {
                          if (!content) {
                            return;
                          }
                          updateAndPushHomepageContent({
                            ...content,
                            documents: [...(content?.documents || []), value],
                          });
                        }}
                      />
                    </Container>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div id="contact">
        <CardsFooter />
      </div>
    </>
  );
};

export default Homepage;
