import { Button } from "reactstrap";
import useModal from "./ModalComponent";


const DeleteButton = (props: { onConfirm: () => void }) => {
  const { openModal, component, closeModal } = useModal();
  return (
    <>
      {component}
      <Button
        className="btn-icon btn-2 ml-1 btn-sm"
        color="outline-danger"
        type="button"
        onClick={() => {
          openModal({
            title: "Confirm delete",
            type: 'delete',
            value: {},
            onConfirm: () => {
              props.onConfirm();
              closeModal();
            },
          });
        }}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-remove" />
        </span>
      </Button>
    </>
  );
};

export default DeleteButton;
