import { useState } from "react";
import Select, { components } from "react-select";
import { InputGroup, InputGroupText, Input } from "reactstrap";

interface Props {
  value: any;
  onChange: (value: any) => void;
  label?: string;
}

export const Link = ({ value, onChange, label = "Link" }: Props) => {
  const getType = () => {
    if (value?.link?.includes("@")) {
      return "email";
    }
    return (value?.link?.startsWith("http") && "external") || "internal";
  };
  const [type, setType] = useState<string>(getType());

  const typeOptions = [
    { label: "Internal", value: "internal" },
    { label: "External", value: "external" },
    { label: "Email", value: "email" },
  ];

  const pageOptions = [
    { label: "Team", value: "/team" },
    { label: "Roadmap", value: "/roadmap" },
    { label: "Gallery", value: "/gallery" },
    { label: "FAQs", value: "/faqs" },
    { label: "Community", value: "/giveaway" },
    { label: "Story", value: "/#story" },
    { label: "Contact", value: "/#contact" },
    { label: "Community", value: "/#community" },
    { label: "Team page d'accueil", value: "/#team" },
    
  ];

  return (
    <>
      <p className="text-lead">{label}</p>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Label</InputGroupText>
        <Input
          placeholder="title"
          type="text"
          value={value?.label}
          onChange={(e) =>
            onChange({
              ...value,
              label: e.target.value,
            })
          }
        />
      </InputGroup>

      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Type</InputGroupText>
        <Select
          components={{
            SelectContainer: ({ children }) => (
              <div
                style={{
                  padding: 0,
                  border: "none",
                  flexGrow: 1,
                }}
                className="form-control"
              >
                {children}
              </div>
            ),
            Control: ({ children, className, ...rest }) => (
              <components.Control {...rest}>{children}</components.Control>
            ),
          }}
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: "0 0.25rem 0.25rem 0",
              height: "100%",
              borderLeft: 0,
            }),
          }}
          value={
            typeOptions.find(({ value }) => value === type) || {
              label: "",
              value: "",
            }
          }
          options={typeOptions}
          onChange={(e: any) => setType(e.value)}
        />
      </InputGroup>

      {(type === "external" && (
        <InputGroup className="mb-2">
          <InputGroupText style={{ width: "25%" }}>
            Url (https://)
          </InputGroupText>
          <Input
            placeholder="title"
            type="text"
            value={value?.link}
            onChange={(e) =>
              onChange({
                ...value,
                link: e.target.value,
              })
            }
          />
        </InputGroup>
      )) ||
        (type === "email" && (
          <InputGroup className="mb-2">
            <InputGroupText style={{ width: "25%" }}>Email</InputGroupText>
            <Input
              placeholder="title"
              type="text"
              value={value?.link}
              onChange={(e) =>
                onChange({
                  ...value,
                  link: e.target.value,
                })
              }
            />
          </InputGroup>
        )) || (
          <InputGroup className="mb-2">
            <InputGroupText style={{ width: "25%" }}>Page</InputGroupText>
            <Select
              components={{
                SelectContainer: ({ children }) => (
                  <div
                    style={{
                      padding: 0,
                      border: "none",
                      flexGrow: 1,
                    }}
                    className="form-control"
                  >
                    {children}
                  </div>
                ),
                Control: ({ children, className, ...rest }) => (
                  <components.Control {...rest}>{children}</components.Control>
                ),
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  borderRadius: "0 0.25rem 0.25rem 0",
                  height: "100%",
                  borderLeft: 0,
                }),
              }}
              value={
                pageOptions.find(({ value: v }) => v === value?.link) || {
                  label: "",
                  value: "",
                }
              }
              options={pageOptions}
              onChange={(e: any) =>
                onChange({
                  ...value,
                  link: e.value,
                })
              }
            />
          </InputGroup>
        )}
    </>
  );
};
