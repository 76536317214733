import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";
import ImageSelector from "./ImageSelector";

interface Props {
  value: any;
  onChange: (value: any) => void;
}
const MemberForm = ({ value, onChange }: Props) => {
  return (
    <>
      <ImageSelector
        value={value.picture}
        onChange={(e) => onChange({ ...value, picture: e })}
      />
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Fullname</InputGroupText>
        <Input
          placeholder="Label"
          type="text"
          value={value.name}
          onChange={(e) =>
            onChange({
              ...value,
              name: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Job</InputGroupText>
        <Input
          placeholder="Label"
          type="text"
          value={value.job}
          onChange={(e) =>
            onChange({
              ...value,
              job: e.target.value,
            })
          }
        />
      </InputGroup>
      <FormGroup>
        <textarea
          className="form-control"
          rows={5}
          placeholder="subtitle"
          value={value.description}
          onChange={(e) =>
            onChange({
              ...value,
              description: e.target.value,
            })
          }
        />
      </FormGroup>
    </>
  );
};

export default MemberForm;
