import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/all.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Homepage from "pages/Homepage";
import Team from "pages/Team";
import Roadmap from "pages/Roadmap";
import FAQ from "pages/FAQ";
import Gallery from "pages/Gallery";
import Login from "pages/Login";
import { UserProvider } from "context/user.context";
import { useEffect, useRef } from "react";
import Mask from "pages/Mask";

function App() {
  const backTopButtonRef = useRef<HTMLButtonElement | null>(null);

  function scrollFunction() {
    const button = backTopButtonRef?.current;

    if (!button) {
      return;
    }

    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      button.style.transform = "scale(1)";
    } else {
      button.style.transform = "scale(0)";
    }
  }

  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    window.removeEventListener("scroll", scrollFunction);
    window.addEventListener("scroll", scrollFunction);
  }, []);

  return (
    <UserProvider>
      <button
        type="button"
        ref={backTopButtonRef}
        className="btn btn-primary btn-floating btn-lg btn d-block d-lg-none d-xl-none"
        id="btn-back-to-top"
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 500,
          cursor: "pointer",
          transition: 'ease all .5s',
          transform: 'scale(0)'
        }}
        onClick={backToTop}
      >
        <i className="fa fa-arrow-up"></i>
      </button>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/team" element={<Team />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/giveaway" element={<Mask />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
