import { createContext, Dispatch, ReactNode, useState } from "react";

export interface UserContextInterface {
  isLogin: boolean;
  isEditing: boolean;
}

const UserContext = createContext<[UserContextInterface, Dispatch<UserContextInterface>]>([{
  isLogin: false,
  isEditing: false,
}, () => undefined]);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function UserProvider({ children }: { children: ReactNode }) {
  const userDetails = useState<UserContextInterface>({
    isLogin: !!sessionStorage.getItem('api_token'),
    isEditing: false,
  });

  return (
    <UserContext.Provider value={userDetails}>
        {children}
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext };
