/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { UserContext } from "context/user.context";
import { PageContent } from "data/models/page";
import usePage from "data/services/page.service";
import EditButton from "pages/components/EditButton";
import { useContext, useEffect, useState } from "react";
import useLink from "../../pages/hooks/UseLink";
// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

const networks = [
  {
    name: "Facebook",
    icon: "fa-brands fa-facebook-square",
    link: "https://www.facebook.com/happyheeps",
  },
  {
    name: "Twitter",
    icon: "fa-brands fa-twitter-square",
    link: "https://twitter.com/happyheeps",
  },
  {
    name: "Instagram",
    icon: "fa-brands fa-instagram",
    link: "https://www.instagram.com/happyheeps",
  },
  {
    name: "Linkedin",
    icon: "fa-brands fa-linkedin",
    link: "https://linkedin.com/company/heepstreet/",
  },
  {
    name: "Reddit",
    icon: "fa-brands fa-reddit",
    link: "https://www.reddit.com/r/HappyHeeps/",
  },
  {
    name: "Tik-tok",
    icon: "fa-brands fa-tiktok",
    link: "https://www.tiktok.com/@happyheepsproject",
  },
  {
    name: "Discord",
    icon: "fa-brands fa-discord",
    link: "https://discord.gg/w5nENEQFhh",
  },
];

const CardsFooter = () => {
  const [{ isEditing }] = useContext(UserContext);

  const [content, setContent] = useState<PageContent | null>(null);
  const [id, setId] = useState<number>(0);
  const page = usePage();
  const { goTo } = useLink();

  useEffect(() => {
    page.getByName("contact").then(({ content = {}, id = 0 }) => {
      setContent(content);
      setId(id);
    });
  }, []);

  const updateAndPushHomepageContent = (content: PageContent) => {
    setContent(content);
    page.updatePage({
      id,
      tag: "contact",
      title: "contact",
      content,
    });
  };

  return (
    <>
      <section className="section section-lg bg-gradient-default">
        <Container>
          <Row className="row-grid justify-content-center">
            <Col className="text-center" lg="8">
              <h2 className="display-3 text-white">
                {content?.header?.title || " You want to join our community"}
                {isEditing && (
                  <EditButton
                    title="Update contact"
                    type="contact"
                    value={content?.header || {}}
                    onConfirm={(value) =>
                      updateAndPushHomepageContent({
                        ...content,
                        header: value,
                      })
                    }
                  />
                )}
                <div className="text-success">
                  {content?.header?.subtitle || "Get in touch with us ?"}
                </div>
              </h2>
              <p className="lead text-white">
                {content?.header?.text ||
                  "You are an artist, represent a brand, writing a piece about HappyHeeps or just want to connect with our team, contact us:"}
              </p>
              <Row className="justify-content-center d-flex">
                {content?.header?.link1?.label && (
                  <div className="btn-wrapper">
                    <Button
                      className="mb-3 mb-sm-0"
                      color="primary"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        goTo(content?.header?.link1?.link || "#");
                      }}
                    >
                      {content?.header?.link1?.label}
                    </Button>
                  </div>
                )}
                {content?.header?.link2?.label && (
                  <div className="btn-wrapper">
                    <Button
                      className="mb-3 mb-sm-0 ml-3"
                      color="warning"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        goTo(content?.header?.link2?.link || "#");
                      }}
                    >
                      {content?.header?.link2?.label}
                    </Button>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>

      <footer className="footer has-cards">
        <Container>
          <Row className="row-grid align-items-center my-md">
            <Col lg="6">
              <h3 className="text-primary font-weight-light mb-2">
                Follow HappyHeeps
              </h3>
            </Col>
            <Col
              className="text-lg-center btn-wrapper"
              lg="6"
              style={{ textAlign: "end" }}
            >
              {networks.map((network) => (
                <span key={network.name} className={"mr-2"}>
                  <Button
                    className="btn-icon-only rounded-circle"
                    color={network.name.replace("-", "").toLowerCase()}
                    href={network.link}
                    id={network.name}
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className={network.icon} />
                    </span>
                  </Button>
                  <UncontrolledTooltip delay={0} target={network.name}>
                    Follow us on {network.name}
                  </UncontrolledTooltip>
                </span>
              ))}
            </Col>
          </Row>
          <hr />
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                © {new Date().getFullYear()} Heepstreet Canada Corp.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default CardsFooter;
