import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";
import ImageSelector from "./ImageSelector";
import { Link } from "./Link";
interface Props {
  value: any;
  onChange: (value: any) => void;
}
const ContactForm = ({ value, onChange }: Props) => {
  return (
    <>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Title</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value?.title}
          onChange={(e) =>
            onChange({
              ...value,
              title: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Subtitle</InputGroupText>
        <Input
          placeholder="Subtitle"
          type="text"
          value={value?.subtitle}
          onChange={(e) =>
            onChange({
              ...value,
              subtitle: e.target.value,
            })
          }
        />
      </InputGroup>
      <FormGroup className="mb-2">
        <textarea
          className="form-control"
          rows={5}
          placeholder="Text"
          value={value?.text}
          onChange={(e) =>
            onChange({
              ...value,
              text: e.target.value,
            })
          }
        />
      </FormGroup>
      <Link
        label="Link 1"
        value={value?.link1}
        onChange={(link) =>
          onChange({
            ...value,
            link1: link,
          })
        }
      />
      <Link
        label="Link 2"
        value={value?.link2}
        onChange={(link) =>
          onChange({
            ...value,
            link2: link,
          })
        }
      />
    </>
  );
};

export default ContactForm;
