import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";
import IconSelector from "./IconSelector";
import ImageSelector from "./ImageSelector";
import { Link } from "./Link";

interface Props {
  value: any;
  onChange: (value: any) => void;
}
const WhitepaperForm = ({ value, onChange }: Props) => {
  return (
    <>
      <ImageSelector
        value={value.picture}
        onChange={(picture) =>
          onChange({
            ...value,
            picture,
          })
        }
      />
      <IconSelector
        value={value.icon}
        onChange={(icon) =>
          onChange({
            ...value,
            icon,
          })
        }
      />
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Title</InputGroupText>
        <Input
          placeholder="Label"
          type="text"
          value={value.title}
          onChange={(e) =>
            onChange({
              ...value,
              title: e.target.value,
            })
          }
        />
      </InputGroup>
      <FormGroup>
        <textarea
          className="form-control"
          rows={5}
          placeholder="subtitle"
          value={value.subtitle}
          onChange={(e) =>
            onChange({
              ...value,
              subtitle: e.target.value,
            })
          }
        />
      </FormGroup>
      <hr className="divider mb-2" />
      <p className="text-lead">Card</p>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Title</InputGroupText>
        <Input
          placeholder="Label"
          type="text"
          value={value.card.title}
          onChange={(e) =>
            onChange({
              ...value,
              card: {
                ...value.card,
                title: e.target.value,
              },
            })
          }
        />
      </InputGroup>
      <FormGroup>
        <textarea
          className="form-control"
          rows={5}
          placeholder="text"
          value={value.card.subtitle}
          onChange={(e) =>
            onChange({
              ...value,
              card: {
                ...value.card,
                subtitle: e.target.value,
              },
            })
          }
        />
      </FormGroup>
      <hr className="divider mb-2" />
      <Link
        value={value.link}
        onChange={(link) =>
          onChange({
            ...value,
            link: link,
          })
        }
      />
    </>
  );
};

export default WhitepaperForm;
