import { InputGroup, InputGroupText, Input } from "reactstrap";
import ImageSelector from "./ImageSelector";

interface Props {
    value: any,
    onChange: (value: any) => void,
}
const MaskForm = ({value, onChange}: Props) => {
  return (
    <>
      <ImageSelector
        value={value}
        onChange={(picture) =>
          onChange({
              ...value,
              picture: picture.content,
          })
        }
      />
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Name</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value.name}
          onChange={(e) =>
            onChange({
                ...value,
                name: e.target.value,
            })
          }
        />
      </InputGroup>
    </>
  );
};

export default MaskForm;
