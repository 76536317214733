import { useNavigate } from "react-router-dom";

const UseLink = () => {
  const navigate = useNavigate();

  return {
    goTo: (link: string) => {
      if (link.includes("@")) {
        window.location.href = `mailto:${link}`;
        return;
      }

      if (link.startsWith("http")) {
        window.open(link, "_blank");
        return;
      }

      navigate(link);
    },
    scrollToHash: (hash: string) => {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, 0);
    },
  };
};

export default UseLink;
