import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";

interface Props {
  value: any;
  onChange: (value: any) => void;
}
const PressKitForm = ({ value, onChange }: Props) => {
  return (
    <>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Title</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value?.title}
          onChange={(e) =>
            onChange({
              ...(value || {}),
              title: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Subtitle</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value?.subtitle}
          onChange={(e) =>
            onChange({
              ...(value || {}),
              subtitle: e.target.value,
            })
          }
        />
      </InputGroup>
      <FormGroup className="mb-2">
        <textarea
          className="form-control"
          rows={5}
          placeholder="Text"
          value={value?.text}
          onChange={(e) =>
            onChange({
              ...(value || {}),
              text: e.target.value,
            })
          }
        />
      </FormGroup>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Links title</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value?.links}
          onChange={(e) =>
            onChange({
              ...(value || {}),
              links: e.target.value,
            })
          }
        />
      </InputGroup>
    </>
  );
};

export default PressKitForm;
