import { Button } from "reactstrap";
import useModal, { ModalProps } from "./ModalComponent";

const EditButton = (props: ModalProps) => {
  const { openModal, component, closeModal } = useModal();
  return (
    <>
      {component}
      <Button
        className="btn-icon btn-2 ml-1 btn-sm"
        color="outline-success"
        type="button"
        onClick={() => {
          openModal({
            ...props,
            onConfirm: (value: any) => {
              props.onConfirm(value);
              closeModal();
            },
          });
        }}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-pencil" />
        </span>
      </Button>
    </>
  );
};

export default EditButton;
