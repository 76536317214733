import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";

interface Props {
  value: any;
  onChange: (value: any) => void;
}
const TeamForm = ({ value, onChange }: Props) => {
  return (
    <>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Title</InputGroupText>
        <Input
          placeholder="Label"
          type="text"
          value={value.title}
          onChange={(e) =>
            onChange({
              ...value,
              title: e.target.value,
            })
          }
        />
      </InputGroup>
      <FormGroup>
        <textarea
          className="form-control"
          rows={5}
          placeholder="subtitle"
          value={value.subtitle}
          onChange={(e) =>
            onChange({
              ...value,
              subtitle: e.target.value,
            })
          }
        />
      </FormGroup>
    </>
  );
};

export default TeamForm;
