// reactstrap components
import {
  Container,
  Row,
  Col,
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar";
import CardsFooter from "components/Footers/CardsFooter";
import Loader from "components/Loader";
import { useContext, useEffect, useState } from "react";
import { FaqItem } from "data/models/faq";
import { PageContent } from "data/models/page";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";
import AddButton from "./components/AddButton";
import { UserContext } from "context/user.context";
import usePage from "data/services/page.service";
import useFaqs from "data/services/faqs.service";
import Skeleton from "./Skeleton";

const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [{ isEditing }] = useContext(UserContext);
  const page = usePage();
  const service = useFaqs();

  const [content, setContent] = useState<PageContent | null>(null);
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    page.getByName("faqs").then(({ content, id }) => {
      setContent(content);
      setId(id);
      setIsLoading(false);
    });

    service.getAll().then((items) => {
      setItems(items);
    });
  }, []);

  const [items, setItems] = useState<FaqItem[]>([]);

  const updateAndPushHomepageContent = (content: PageContent) => {
    setIsLoading(true);

    setContent(content);
    page
      .updatePage({
        id,
        tag: "faqs",
        title: "faqs",
        content,
      })
      .then(() => setIsLoading(false));
  };

  const updateAndPushItems = (content: FaqItem[]) => {
    setItems(content);
  };

  if (!id) {
    return <Skeleton />;
  }
  
  return (
    <>
      <Loader isLoading={isLoading} />
      <Navbar />
      <main>
        <div className="position-relative">
          <section
            className="section section-lg section-shaped pb-100 pt-150"
            style={{
              backgroundImage: `url(${content?.header.picture.content})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {content?.header.title}{" "}
                      {isEditing && (
                        <EditButton
                          title="Update Header"
                          type="page_header"
                          value={content?.header}
                          onConfirm={(value) =>
                            updateAndPushHomepageContent({
                              ...content,
                              header: value,
                            })
                          }
                        />
                      )}
                      <span>{content?.header.subtitle} </span>
                    </h1>
                    <p className="lead text-white">{content?.header.text} </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  style={{
                    fill: "#f4f5f7",
                  }}
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--100">
          <Container>
            <Row className="">
              <UncontrolledAccordion
                open=""
                defaultOpen={["0"]}
                stayOpen
                style={{
                  width: "100%",
                }}
              >
                {items.map((item, index) => (
                  <AccordionItem key={index}>
                    <AccordionHeader
                      targetId={index.toString()}
                      color="primary"
                    >
                      {item.title}
                      {isEditing && (
                        <div
                          className="flex-grow-1 d-flex justify-content-end mr-2"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <>
                            <EditButton
                              title="Update FAQ"
                              type="faq_item"
                              value={item}
                              onConfirm={(value) => {
                                service.updateFaqs(value);
                                updateAndPushItems(
                                  items.map(
                                    (item, i) => (i === index && value) || item
                                  )
                                );
                              }}
                            />
                            <DeleteButton
                              onConfirm={() => {
                                service.deleteFaqs(item);
                                updateAndPushItems(
                                  items.filter((_, i) => i !== index)
                                );
                              }}
                            />
                          </>
                        </div>
                      )}
                    </AccordionHeader>
                    <AccordionBody
                      accordionId={index.toString()}
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.subtitle}
                    </AccordionBody>
                  </AccordionItem>
                ))}
              </UncontrolledAccordion>
            </Row>
          </Container>
          {isEditing && (
            <Container className="justify-content-center d-flex">
              <AddButton
                title="Add roadmap"
                type="faq_item"
                value={{
                  title: "",
                  subtitle: "",
                }}
                onConfirm={async (value) => {
                  const added = await service.addFaqs(value);
                  updateAndPushItems([...items, added]);
                }}
              />
            </Container>
          )}
        </section>
      </main>
      <CardsFooter />
    </>
  );
};

export default Homepage;
