import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";
import IconSelector from "./IconSelector";
import ImageSelector from "./ImageSelector";
import { Link } from "./Link";

interface Props {
    value: any,
    onChange: (value: any) => void,
}
const HeaderForm = ({value, onChange}: Props) => {
  return (
    <>
      <ImageSelector
        value={value.picture}
        onChange={(picture) =>
          onChange({
              ...value,
              picture,
          })
        }
      />
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Title</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value.title}
          onChange={(e) =>
            onChange({
                ...value,
                title: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Subtitle</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value.subtitle}
          onChange={(e) =>
            onChange({
                ...value,
                subtitle: e.target.value,
            })
          }
        />
      </InputGroup>
      <FormGroup className="mb-2">
        <textarea
          className="form-control"
          rows={5}
          placeholder="Text"
          value={value.text}
          onChange={(e) =>
            onChange({
                ...value,
                text: e.target.value,
            })
          }
        />
      </FormGroup>
      <hr />
      <Link
        value={value.link}
        onChange={(link) =>
          onChange({
              ...value,
              link: link,
          })
        }
      />
      <IconSelector
        value={value.link.icon}
        onChange={(icon) =>
          onChange({
              ...value,
              link: {
                ...value.link,
                icon,
            },
          })
        }
      />
    </>
  );
};

export default HeaderForm;
