/* eslint-disable react-hooks/exhaustive-deps */
import { Attribute, Collectible } from "data/models/collectible";
import { GroupAttribute } from "pages/Gallery";
import { useEffect, useState } from "react";

interface Search {
  backgrounds: GroupAttribute[];
  faces: GroupAttribute[];
  hats: GroupAttribute[];
  tattoos: GroupAttribute[];
  glasses: GroupAttribute[];
  accessories: GroupAttribute[];
  page: number;
}
const useCollection = () => {
  const [collectibles, setCollectibles] = useState<Collectible[]>([]);

  const [search, setSearch] = useState<Search>({
    backgrounds: [],
    faces: [],
    hats: [],
    tattoos: [],
    glasses: [],
    accessories: [],
    page: 1,
  });

  useEffect(() => {
    refresh().then((newCollectibles) => {
      if (search.page === 1) {
        setCollectibles(newCollectibles);
        return;
      }

      setCollectibles([...collectibles, ...newCollectibles]);
    });
  }, [search]);

  const findAttributes = async (name: string, filter = "") => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/collection/attributes/${name}?filter=${filter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      }
    );

    return await response.json();
  };

  const updateFilter = (type: keyof Search, values: Attribute[]) => {
    setSearch({
      ...search,
      page: 1,
      [type]: values,
    });
  };

  const resetFilter = () => {
    setSearch({
      backgrounds: [],
      faces: [],
      hats: [],
      tattoos: [],
      glasses: [],
      accessories: [],
      page: 1,
    });
  };

  const refresh = async () => {
    const flatten = (arr: string[][]): string[] =>
      [].concat.apply([], arr as unknown as never);
    const response = await fetch(
      `${
        process.env.REACT_APP_API_URL
      }/collection/collectibles?${new URLSearchParams({
        backgrounds: flatten(search.backgrounds.map(({ ids }) => ids)),
        faces: flatten(search.faces.map(({ ids }) => ids)),
        hats: flatten(search.hats.map(({ ids }) => ids)),
        tattoos: flatten(search.tattoos.map(({ ids }) => ids)),
        glasses: flatten(search.glasses.map(({ ids }) => ids)),
        accessories: flatten(search.accessories.map(({ ids }) => ids)),
        page: search.page,
      } as unknown as string).toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      }
    );

    return await response.json();
  };

  const next = async () => {
    setSearch({
      ...search,
      page: search.page + 1,
    });
  };

  return {
    collectibles,
    next,
    findAttributes,
    updateFilter,
    search,
    resetFilter,
  };
};

export default useCollection;
