import { FaqItem } from "data/models/faq";
import { useNavigate } from "react-router-dom";

const useFaqs = () => {
  const navigate = useNavigate();
  const getAll = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/faqs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });

    return await response.json();
  };

  const updateFaqs = async (faqs: FaqItem) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/faqs/${faqs.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
        body: JSON.stringify(faqs),
        redirect: "follow",
      }
    );

    const result = await response.json();

    if (result?.statusCode === 401) {
      navigate("/login");
      return;
    }

    return result;
  };

  const addFaqs = async (faqs: FaqItem) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/faqs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
      body: JSON.stringify(faqs),
      redirect: "follow",
    });

    const result = await response.json();

    if (result?.statusCode === 401) {
      navigate("/login");
      return;
    }

    return result;
  };

  const deleteFaqs = async (faqs: FaqItem) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/faqs/${faqs.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
        redirect: "follow",
      }
    );

    const result = await response.json();

    if (result?.statusCode === 401) {
      navigate("/login");
      return;
    }

    return result;
  };

  return {
    getAll,
    updateFaqs,
    addFaqs,
    deleteFaqs,
  };
};

export default useFaqs;
