/* eslint-disable react-hooks/exhaustive-deps */
// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Badge,
  Modal,
  CardBody,
  CardHeader,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Input,
  CardImg,
  Button,
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar";
import CardsFooter from "components/Footers/CardsFooter";
import Loader from "components/Loader";
import { useContext, useEffect, useRef, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import useCollection from "data/services/collection.service";
import { Attribute, Collectible } from "data/models/collectible";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "context/user.context";
import { PageContent } from "data/models/page";
import usePage from "data/services/page.service";
import EditButton from "./components/EditButton";

const CollectionComponent = ({
  collectible,
}: {
  collectible: Collectible | null;
}) => {
  if (!collectible) {
    return <></>;
  }
  const getColor = () => {
    switch (collectible.subCollection) {
      case "CREATIVES":
        return "primary";
      case "BUSINESS":
        return "danger";
      default:
        return "success";
    }
  };

  return (
    <Badge color={getColor()} pill className="mb-4">
      {collectible.subCollection}
    </Badge>
  );
};

export interface GroupAttribute {
  id: string;
  name: string;
  ids: string[];
}

const FilterComponent = ({
  name,
  type,
  onChange,
  selected,
  label,
  group = true,
}: {
  name: string;
  label: string;
  type: string;
  selected: Attribute[];
  onChange: (values: Attribute[]) => void;
  group?: boolean;
}) => {
  const { findAttributes } = useCollection();
  const [search, setSearch] = useState("");
  const [attributes, setAttributes] = useState<GroupAttribute[]>([]);

  const toggAttribute = (attribute: GroupAttribute, checked: boolean) => {
    if (!checked) {
      onChange(selected.filter((v) => v !== attribute));
      return;
    }

    onChange([...selected, attribute]);
  };

  useEffect(() => {
    findAttributes(type).then((attributes: Attribute[]) => {
      if (!group) {
        setAttributes(
          attributes.map((attribute) => ({
            ...attribute,
            name: attribute?.name.replace(" ", "-").toLowerCase(),
            ids: [attribute.id],
          }))
        );
        return;
      }
      setAttributes(
        attributes.reduce((acc, cur) => {
          const name = cur.name
            .replace("Background ", "")
            .split(" ")?.[0]
            .toLowerCase();
          const index = acc.findIndex(({ name: n }) => n === name);

          if (index >= 0) {
            acc[index] = {
              ...acc[index],
              ids: [...acc[index].ids, cur.id],
            };

            return acc;
          }
          return [
            ...acc,
            {
              id: cur.id,
              name: name,
              ids: [cur.id],
            },
          ];
        }, [] as GroupAttribute[])
      );
    });
  }, [type]);
  return (
    <AccordionItem>
      <AccordionHeader targetId={name} color="primary">
        {label} <br />
      </AccordionHeader>
      <AccordionBody
        accordionId={name}
        style={{
          whiteSpace: "pre-line",
          maxHeight: 250,
          overflow: "auto",
        }}
      >
        <FormGroup
          className="bg-white"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <Input
            placeholder={`Search ${name.toLowerCase()}`}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <BadgeFilter
            name=""
            attributes={selected}
            onChange={(values) => onChange(values)}
          />
        </FormGroup>
        <ul className="checkbox-image">
          {attributes
            .filter((attribute) =>
              search
                ? attribute.name.toLowerCase().includes(search.toLowerCase())
                : true
            )
            .map((attribute) => (
              <li key={attribute.id}>
                <input
                  className="custom-control-input"
                  checked={selected.map(({ id }) => id).includes(attribute.id)}
                  id={attribute.id}
                  type="checkbox"
                />
                <label htmlFor={attribute.id}>
                  <img
                    onClick={(e) => {
                      e.preventDefault();
                      const checked = selected
                        .map(({ id }) => id)
                        .includes(attribute.id);
                      toggAttribute(attribute, !checked);
                    }}
                    alt={attribute.name}
                    src={`${
                      process.env.REACT_APP_API_BUCKET
                    }/filters/${name.toLowerCase()}/${attribute.name}.png`}
                  />
                </label>
              </li>
            ))}
        </ul>
      </AccordionBody>
    </AccordionItem>
  );
};

const BadgeFilter = ({
  attributes,
  onChange,
  name,
}: {
  name: string;
  attributes: Attribute[];
  onChange: (attributes: Attribute[]) => void;
}) => {
  return (
    <>
      {attributes.map((attribute) => (
        <Badge color="info" className="mr-2 mb-2">
          {(name && `${name} :`) || ""}
          {attribute?.name}
          <i
            className="fa fa-times ml-2"
            style={{ cursor: "pointer" }}
            onClick={() =>
              onChange(attributes.filter(({ id }) => id !== attribute.id))
            }
          />
        </Badge>
      ))}
    </>
  );
};

const Gallery = () => {
  const [modal, setModal] = useState<Collectible | null>(null);

  const { collectibles, updateFilter, search, next, resetFilter } =
    useCollection();

  const [hover, setHover] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [{ isEditing }] = useContext(UserContext);
  const page = usePage();

  const [content, setContent] = useState<PageContent | null>(null);
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    page.getByName("gallery").then(({ content, id }) => {
      setContent(content);
      setId(id);
      setIsLoading(false);
    });
  }, []);

  const updateAndPushHomepageContent = (content: PageContent) => {
    setIsLoading(true);

    setContent(content);
    page
      .updatePage({
        id,
        tag: "gallery",
        title: "gallery",
        content,
      })
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const backTopButtonRef = useRef<HTMLButtonElement | null>(null);

  function scrollFunction() {
    const button = backTopButtonRef?.current;

    if (!button) {
      return;
    }

    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      button.style.transform = "scale(1)";
    } else {
      button.style.transform = "scale(0)";
    }
  }

  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    window.removeEventListener("scroll", scrollFunction);
    window.addEventListener("scroll", scrollFunction);
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <button
        type="button"
        ref={backTopButtonRef}
        className="btn btn-primary btn-floating btn-lg btn d-block"
        id="btn-back-to-top"
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 500,
          cursor: "pointer",
          transition: "ease all .5s",
          transform: "scale(0)",
        }}
        onClick={backToTop}
      >
        <i className="fa fa-arrow-up"></i>
      </button>
      <Navbar />
      <main>
        <div className="position-relative">
          <section
            className="section section-lg section-shaped pb-300 pt-150"
            style={{
              backgroundImage: `url(${content?.header.picture.content})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {content?.header.title}{" "}
                      {isEditing && (
                        <EditButton
                          title="Update Header"
                          type="page_header"
                          value={content?.header}
                          onConfirm={(value) =>
                            updateAndPushHomepageContent({
                              ...content,
                              header: value,
                            })
                          }
                        />
                      )}
                      <span>{content?.header.subtitle} </span>
                    </h1>
                    <p className="lead text-white">{content?.header.text} </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  style={{
                    fill: "#fff",
                  }}
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--200">
          <div
            className="row mx-sm-6"
            style={{
              marginLeft: "1.5rem",
              marginRight: "1.5rem",
            }}
          >
            <div className="col-lg-3 border-right">
              <h6 className={`text-primary text-uppercase bg-white p-3 pb-0`}>
                {" "}
                <div
                  className={`icon icon-shape icon-shape-primary rounded-circle mr-2`}
                >
                  <i className="fa fa-search" />
                </div>
                Filter
              </h6>
              <UncontrolledAccordion
                open=""
                defaultOpen={["Faces"]}
                stayOpen
                style={{
                  width: "100%",
                  marginBottom: 48,
                }}
              >
                <FilterComponent
                  name="Faces"
                  label="Faces"
                  type="face"
                  selected={search.faces}
                  onChange={(values) => updateFilter("faces", values)}
                />

                <FilterComponent
                  name="Backgrounds"
                  label="Backgrounds / Groups"
                  type="background"
                  selected={search.backgrounds}
                  onChange={(values) => updateFilter("backgrounds", values)}
                />
                <FilterComponent
                  name="Hats"
                  label="Hats"
                  type="hat"
                  selected={search.hats}
                  onChange={(values) => updateFilter("hats", values)}
                />
                <FilterComponent
                  name="Tattoos"
                  label="Tattoos"
                  type="tattoo"
                  selected={search.tattoos}
                  onChange={(values) => updateFilter("tattoos", values)}
                />
                <FilterComponent
                  name="Glasses"
                  label="Glasses"
                  type="glass"
                  selected={search.glasses}
                  group={false}
                  onChange={(values) => updateFilter("glasses", values)}
                />
                <FilterComponent
                  name="Accessories"
                  label="Accessories"
                  type="accessory"
                  selected={search.accessories}
                  onChange={(values) => updateFilter("accessories", values)}
                />
              </UncontrolledAccordion>
            </div>
            <div className="col-lg-9" id="scrollableDiv">
              <InfiniteScroll
                dataLength={collectibles.length}
                next={next}
                hasMore={true}
                loader={<></>}
                scrollableTarget="infinite-scroll"
              >
                <div className="d-flex flex-wrap">
                  {!collectibles.length && (
                    <Card
                      className="bg-white shadow border-0 align-self-center mx-auto mb-4"
                      style={{ width: "50%" }}
                    >
                      <CardImg
                        alt={"No result"}
                        src={"https://api.happyheeps.com/picture/24"}
                        top
                      />
                      <blockquote className="card-blockquote">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="svg-bg"
                          preserveAspectRatio="none"
                          viewBox="0 0 583 95"
                        >
                          <polygon
                            className="fill-default"
                            points="0,52 583,95 0,95"
                          />
                          <polygon
                            className="fill-default"
                            opacity=".2"
                            points="0,42 583,95 683,0 0,95"
                          />
                        </svg>
                        <h4 className="display-3 font-weight-bold">
                          No results found
                        </h4>
                        <p className="lead text-italic">
                          There are no results matching your search. Update your
                          filters to discover our collection
                        </p>
                        <Button
                          className="mb-3 mb-sm-0"
                          color="warning"
                          onClick={() => resetFilter()}
                        >
                          Reset filters
                        </Button>
                      </blockquote>
                    </Card>
                  )}
                  {collectibles.map((collectible, i) => (
                    <div
                      className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-1"
                      onMouseEnter={() => setHover(i)}
                      onMouseLeave={() => setHover(null)}
                      key={collectible.id}
                      onClick={() => setModal(collectible)}
                    >
                      <Card
                        className="card-profile shadow mb-4"
                        style={{
                          transform:
                            (hover === i && "scale(120%)") || "scale(100%)",
                          position: "relative",
                          transition: "ease all .5s",
                          zIndex: (hover === i && 500) || "auto",
                        }}
                      >
                        <div className="px-4">
                          <Row className="justify-content-center">
                            <div className="card-profile-image mt-3 mb-4">
                              <img
                                alt="..."
                                src={`${process.env.REACT_APP_BUCKET_URL}/${collectible.id}.png`}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </Row>
                          <div className="text-center border-top">
                            <div
                              className="h6 font-weight-300 mt-2"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              HH #{collectible.id}
                            </div>
                            <p className="text-muted font-weight-bold">
                              {collectible?.price} Ξ
                            </p>

                            <CollectionComponent collectible={collectible} />

                            <a
                              href={collectible.openseaUrl}
                              id="Twitter"
                              target="_blank"
                              className="btn btn-secondary btn-sm d-block mb-4"
                              rel="noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              Opensea
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </section>
      </main>
      <CardsFooter />
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{
          maxWidth: 1200,
        }}
        isOpen={!!modal}
        toggle={() => setModal(null)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 profile-page">
            <CardHeader className="bg-white">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModal(null)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </CardHeader>
            <CardBody className="px-lg-6 py-lg-6">
              <div className="row">
                <div className="col-lg-5 justify-content-center d-flex">
                  <img
                    alt={`NFT number ${modal?.id}`}
                    src={`${process.env.REACT_APP_BUCKET_URL}/${modal?.id}.png`}
                    style={{ maxHeight: 600 }}
                    className="mb-4"
                  />
                </div>
                <div className="col-lg-7">
                  <div
                    className="mx-4 card-profile mt-0 d-flex"
                    style={{ flexDirection: "column", height: "100%" }}
                  >
                    <div>
                      <CollectionComponent collectible={modal} />
                    </div>
                    <h3 className="text-dark">HH #{modal?.id}</h3>

                    <div style={{ flexGrow: 1 }} />
                    <div className="d-flex align-items-center">
                      <p className="h3 text-muted font-weight-bold">
                        {modal?.price} Ξ
                      </p>
                      <div style={{ flexGrow: 1 }} />
                      <div className="mb-2">
                        <Badge color="info">
                          {modal?.attributesNumber} attributes
                        </Badge>
                      </div>
                    </div>
                    <div className="card-profile-stats d-flex mx--3 flex-wrap-wrap border-top">
                      <Col>
                        <div className="mb-4">
                          <span className="heading">
                            {modal?.background.name}
                          </span>
                          <span className="description">Background</span>
                        </div>
                        <div className="mb-4">
                          <span className="heading">{modal?.face.name}</span>
                          <span className="description">Face</span>
                        </div>
                        <div className="mb-4">
                          <span className="heading">{modal?.hat.name}</span>
                          <span className="description">Hat</span>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-4">
                          <span className="heading">
                            {modal?.tattoo?.name || "-"}
                          </span>
                          <span className="description">Tattoo</span>
                        </div>
                        <div className="mb-4">
                          <span className="heading">
                            {modal?.accessory?.name || "-"}
                          </span>
                          <span className="description">Accessory</span>
                        </div>
                        <div className="mb-4">
                          <span className="heading">
                            {modal?.glass?.name || "-"}
                          </span>
                          <span className="description">Glasses</span>
                        </div>
                      </Col>
                    </div>
                    <a
                      href={modal?.openseaUrl}
                      className="btn-icon mb-3 mb-sm-0 btn btn-info"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Buy it on Opensea
                    </a>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default Gallery;
