import { RoadmapItem } from "data/models/roadmap";
import { useNavigate } from "react-router-dom";

const useRoadmap = () => {
  const navigate = useNavigate();
  const getAll = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/roadmap`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });

    return await response.json();
  };

  const updateRoadmap = async (roadmap: RoadmapItem) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/roadmap/${roadmap.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
        body: JSON.stringify(roadmap),
        redirect: "follow",
      }
    );

    const result = await response.json();

    if (result?.statusCode === 401) {
      navigate("/login");
      return;
    }

    return result;
  };

  const addRoadmap = async (roadmap: RoadmapItem) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/roadmap`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
      },
      body: JSON.stringify(roadmap),
      redirect: "follow",
    });

    const result = await response.json();

    if (result?.statusCode === 401) {
      navigate("/login");
      return;
    }

    return result;
  };

  const deleteRoadmap = async (roadmap: RoadmapItem) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/roadmap/${roadmap.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
        redirect: "follow",
      }
    );

    const result = await response.json();

    if (result?.statusCode === 401) {
      navigate("/login");
      return;
    }

    return result;
  };

  return {
    getAll,
    updateRoadmap,
    addRoadmap,
    deleteRoadmap,
  };
};

export default useRoadmap;
