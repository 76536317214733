import { InputGroup, InputGroupText, Input, FormGroup } from "reactstrap";
import ImageSelector from "./ImageSelector";
interface Props {
  value: any;
  onChange: (value: any) => void;
}
const DocumentForm = ({ value, onChange }: Props) => {
  return (
    <>
      <ImageSelector
        value={value.link}
        onChange={(link) =>
          onChange({
            ...value,
            link,
          })
        }
      />
      <InputGroup className="mb-2">
        <InputGroupText style={{ width: "25%" }}>Filename</InputGroupText>
        <Input
          placeholder="Text"
          type="text"
          value={value.name}
          onChange={(e) =>
            onChange({
              ...value,
              name: e.target.value,
            })
          }
        />
      </InputGroup>
    
    </>
  );
};

export default DocumentForm;
