const usePicture = () => {

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/picture/upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
        },
        body: formData,
        redirect: "follow",
      }
    );

    const result = await response.json();
    return result;
  };

  return {
    uploadFile,
  };
};

export default usePicture;
