// reactstrap components
import { Card, Container, Row, Col, CardHeader } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar";
import CardsFooter from "components/Footers/CardsFooter";
import Loader from "components/Loader";
import { useContext, useEffect, useState } from "react";
import { MemberContent } from "data/models/members";
import EditButton from "./components/EditButton";
import { PageContent } from "data/models/page";
import DeleteButton from "./components/DeleteButton";
import AddButton from "./components/AddButton";
import { UserContext } from "context/user.context";
import usePage from "data/services/page.service";
import useMember from "data/services/member.service";
import Skeleton from "./Skeleton";

const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ isEditing }] = useContext(UserContext);
  const page = usePage();
  const memberService = useMember();

  const [content, setContent] = useState<PageContent | null>(null);
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    page.getByName("team").then(({ content, id }) => {
      setContent(content);
      setId(id);
      setIsLoading(false);
    });

    memberService.getAll().then((members) => {
      setMembers(members);
    });
  }, []);

  const [members, setMembers] = useState<MemberContent[]>([]);

  const updateAndPushHomepageContent = (content: PageContent) => {
    setIsLoading(true);

    setContent(content);
    page
      .updatePage({
        id,
        tag: "team",
        title: "team",
        content,
      })
      .then(() => setIsLoading(false));
  };

  const updateAndPushMembersContent = (content: MemberContent[]) => {
    setMembers(content);
  };

  if (!id) {
    return <Skeleton />;
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Navbar />
      <main>
        <div className="position-relative">
          <section
            className="section section-lg section-shaped pb-250 pt-150"
            style={{
              backgroundImage: `url(${content?.header.picture.content})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {content?.header.title}{" "}
                      {isEditing && (
                        <EditButton
                          title="Update Header"
                          type="page_header"
                          value={content?.header}
                          onConfirm={(value) =>
                            updateAndPushHomepageContent({
                              ...content,
                              header: value,
                            })
                          }
                        />
                      )}
                      <span>{content?.header.subtitle} </span>
                    </h1>
                    <p className="lead text-white">{content?.header.text} </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--100">
          <Container>
            <Row className="justify-content-center">
              {members.map((member, index) => (
                <Col lg="6" key={index} style={{ marginBottom: 130 }}>
                  <Card className="card-profile shadow">
                    {isEditing && (
                      <CardHeader className="d-flex justify-content-end">
                        <>
                          <EditButton
                            title="Update member"
                            type="member"
                            value={member}
                            onConfirm={(value) => {
                              memberService.updateMember(value);
                              updateAndPushMembersContent(
                                members.map(
                                  (member, i) =>
                                    (i === index && value) || member
                                )
                              );
                            }}
                          />
                          <DeleteButton
                            onConfirm={() => {
                              memberService.deleteMember(member);
                              updateAndPushMembersContent(
                                members.filter((argument, i) => i !== index)
                              );
                            }}
                          />
                        </>
                      </CardHeader>
                    )}
                    <div className="px-4">
                      <Row className="justify-content-center">
                        <div className="card-profile-imag mt--100">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt={member.picture.title}
                              className="rounded-circle"
                              src={member.picture.content}
                              style={{
                                width: 200,
                                height: 200,
                              }}
                            />
                          </a>
                        </div>
                      </Row>
                      <div className="text-center mt-5">
                        <h3>{member.name}</h3>
                        <div className="h6 mt-4">
                          <i className="ni business_briefcase-24 mr-2" />
                          {member.job}
                        </div>
                      </div>
                      <div className="mt-5 py-5 border-top text-center">
                        <Row className="justify-content-center">
                          <Col lg="9">
                            <p>{member.description}</p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
          {isEditing && (
            <Container className="justify-content-center d-flex">
              <AddButton
                title="Add member"
                type="member"
                value={{
                  id: 0,
                  name: "",
                  job: "",
                  description: "",
                  picture: {
                    content: "",
                    title: "",
                  },
                }}
                onConfirm={async (value) => {
                  const newMember = await memberService.addMember(value);
                  updateAndPushMembersContent([...members, newMember]);
                }}
              />
            </Container>
          )}
        </section>
      </main>
      <CardsFooter />
    </>
  );
};

export default Homepage;
