import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { UserContext } from "context/user.context";

const networks = [
  {
    name: "Facebook",
    icon: "fa-brands fa-facebook-square",
    link: "https://www.facebook.com/happyheeps",
  },
  {
    name: "Twitter",
    icon: "fa-brands fa-twitter-square",
    link: "https://twitter.com/happyheeps",
  },
  {
    name: "Instagram",
    icon: "fa-brands fa-instagram",
    link: "https://www.instagram.com/happyheeps",
  },
  {
    name: "Linkedin",
    icon: "fa-brands fa-linkedin",
    link: "https://linkedin.com/company/heepstreet/",
  },
  {
    name: "Reddit",
    icon: "fa-brands fa-reddit",
    link: "https://www.reddit.com/r/HappyHeeps/",
  },
  {
    name: "Tik-tok",
    icon: "fa-brands fa-tiktok",
    link: "https://www.tiktok.com/@happyheepsproject",
  },
  {
    name: "Discord",
    icon: "fa-brands fa-discord",
    link: "https://discord.gg/w5nENEQFhh",
  },
];
const DemoNavbar = () => {
  const [state, setState] = useState({
    collapseClasses: "",
    collapseOpen: false,
  });

  const [user, setUser] = useContext(UserContext);

  useEffect(() => {
    const navbar = document.getElementById("navbar-main");
    if (!navbar) {
      return;
    }
    let headroom = new Headroom(navbar);
    // initialise
    headroom.init();
  }, []);

  const onExiting = () => {
    setState({
      ...state,
      collapseClasses: "collapsing-out",
    });
  };

  const onExited = () => {
    setState({
      ...state,
      collapseClasses: "",
    });
  };

  return (
    <>
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img
                alt="happyheeps nft collection"
                src={require("assets/img/brand/argon-react-white.png")}
              />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={state.collapseClasses}
              onExiting={onExiting}
              onExited={onExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="..."
                        src={require("assets/img/brand/argon-react.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle nav>
                    <i className="fa fa-chevron-down d-lg-none mr-1" />
                    <span className="nav-link-inner--text">About us</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to="/#story" tag={Link}>
                      Story
                    </DropdownItem>
                    <DropdownItem to="/#press-kit" tag={Link}>
                      Press kit
                    </DropdownItem>
                    <DropdownItem to="/#contact" tag={Link}>
                      Contact
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Link to="/gallery" className="nav-link">
                  Collection
                </Link>
                <Link to="/giveaway" className="nav-link">
                  Community
                </Link>
                <Link to="/roadmap" className="nav-link">
                  Roadmap
                </Link>
                <Link to="/team" className="nav-link">
                  Team
                </Link>
                <Link to="/faqs" className="nav-link">
                  FAQs
                </Link>
              </Nav>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                {networks.map((network) => (
                  <NavItem key={network.name}>
                    <NavLink
                      className="nav-link-icon"
                      href={network.link}
                      id={network.name}
                      target="_blank"
                    >
                      <i className={network.icon} />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        {network.name}
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target={network.name}>
                      Follow us on {network.name}
                    </UncontrolledTooltip>
                  </NavItem>
                ))}

                {user.isLogin && (
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      onClick={() => {
                        setUser({
                          ...user,
                          isEditing: !user.isEditing,
                        });
                      }}
                    >
                      {(user.isEditing && (
                        <span className="nav-link-inner--text ml-1">
                          Display mode
                        </span>
                      )) || (
                        <span className="nav-link-inner--text ml-1">
                          Edit mode
                        </span>
                      )}
                    </Button>
                  </NavItem>
                )}
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default DemoNavbar;
