import Loader from "components/Loader";
import usePicture from "data/services/picture.service";
import { useState } from "react";
import { FormGroup, Input } from "reactstrap";

const ImageSelector = ({
  value,
  onChange,
}: {
  value: { content: string; title: string };
  onChange: (value: { content: string | ArrayBuffer; title: string }) => void;
}) => {
  const pictureService = usePicture();
  const [isLoading, setIsLoading] = useState(false);

  const onLoadFile = (event: any) => {
    let input = event.target;
    let file = input.files[0];
    setIsLoading(true);
    pictureService.uploadFile(file).then((picture) => {
      setIsLoading(false);
      onChange({
        content: `${process.env.REACT_APP_API_URL}/picture/${picture.id}`,
        title: file.name || "",
      });
    });
  };
  return (
    <>
      <Loader isLoading={isLoading} />
      <FormGroup>
        <img
          src={value.content}
          width={70}
          alt={value.title}
          className="mb-2"
        />
        <Input id="file" name="file" type="file" onChange={onLoadFile} />
      </FormGroup>
    </>
  );
};

export default ImageSelector;
