// reactstrap components
import { Container, Row, Col, CardHeader, Button } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar";
import CardsFooter from "components/Footers/CardsFooter";
import Loader from "components/Loader";
import { useContext, useEffect, useState } from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { PageContent } from "data/models/page";
import { RoadmapItem } from "data/models/roadmap";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";
import AddButton from "./components/AddButton";
import { UserContext } from "context/user.context";
import usePage from "data/services/page.service";
import useRoadmap from "data/services/roadmap.service";
import Skeleton from "./Skeleton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./roadmap.css";

const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ isEditing }] = useContext(UserContext);
  const page = usePage();

  const [content, setContent] = useState<PageContent | null>(null);
  const [id, setId] = useState<number>(0);
  const service = useRoadmap();

  useEffect(() => {
    page.getByName("roadmap").then(({ content, id }) => {
      setContent(content);
      setId(id);
      setIsLoading(false);
    });

    service.getAll().then((items: RoadmapItem[]) => {
      setItems(
        items
          .map((item, i) => ({
            ...item,
            position: item.position || i,
          }))
          .sort((a, b) => a.position - b.position)
      );
    });
  }, []);

  const [items, setItems] = useState<RoadmapItem[]>([]);

  const updateAndPushHomepageContent = (content: PageContent) => {
    setIsLoading(true);

    setContent(content);
    page
      .updatePage({
        id,
        tag: "roadmap",
        title: "roadmap",
        content,
      })
      .then(() => setIsLoading(false));
  };

  const updateAndPushRoadmapContent = (content: RoadmapItem[]) => {
    setItems(content);
  };

  const reorder = (
    list: RoadmapItem[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDrop = (droppedItem: any) => {
    console.log(droppedItem);
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = reorder(
      items,
      droppedItem.source.index,
      droppedItem.destination.index
    );
    const orderedList = updatedList.map((item, i) => ({
      ...item,
      position: i + 1,
    }));
    setItems(orderedList);
    Promise.all(orderedList.map((value) => service.updateRoadmap(value)));
  };

  if (!id) {
    return <Skeleton />;
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Navbar />
      <main>
        <div className="position-relative">
          <section
            className="section section-lg section-shaped pb-100 pt-150"
            style={{
              backgroundImage: `url(${content?.header.picture.content})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {content?.header.title}{" "}
                      {isEditing && (
                        <EditButton
                          title="Update Header"
                          type="page_header"
                          value={content?.header}
                          onConfirm={(value) =>
                            updateAndPushHomepageContent({
                              ...content,
                              header: value,
                            })
                          }
                        />
                      )}
                      <span>{content?.header.subtitle} </span>
                    </h1>
                    <p className="lead text-white">{content?.header.text} </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  style={{
                    fill: "#f4f5f7",
                  }}
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--2500 bg-secondary">
          {(isEditing && (
            <Container>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <div
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item?.id?.toString()}
                          draggableId={item?.id?.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={`item-container my-4`.trim()}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <CardHeader className="d-flex justify-content-end">
                                <>
                                  <EditButton
                                    title="Update roadmap"
                                    type="roadmap_item"
                                    value={item}
                                    onConfirm={(value) => {
                                      service.updateRoadmap(value);
                                      updateAndPushRoadmapContent(
                                        items.map(
                                          (item, i) =>
                                            (i === index && value) || item
                                        )
                                      );
                                    }}
                                  />
                                  <DeleteButton
                                    onConfirm={() => {
                                      service.deleteRoadmap(item);
                                      updateAndPushRoadmapContent(
                                        items.filter((_, i) => i !== index)
                                      );
                                    }}
                                  />
                                </>
                              </CardHeader>
                              <h3 className="vertical-timeline-element-title">
                                {item.title}
                              </h3>
                              <h4 className="vertical-timeline-element-subtitle">
                                {item.subtitle}
                              </h4>
                              <p>{item.description}</p>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      <Container className="justify-content-center d-flex">
                        <AddButton
                          title="Add roadmap"
                          type="roadmap_item"
                          value={{
                            variant: "",
                            icon: "ni ni-album-2",
                            title: "",
                            subtitle: "",
                            description: "",
                            date: "",
                            isDone: false,
                          }}
                          onConfirm={async (value) => {
                            const added = await service.addRoadmap(value);
                            updateAndPushRoadmapContent([...items, added]);
                          }}
                        />
                      </Container>

                      <VerticalTimelineElement
                        iconStyle={{
                          background: "rgb(16, 204, 82)",
                          color: "#fff",
                        }}
                        icon={
                          <i
                            className="fa fa-star fa-2x"
                            style={{
                              marginTop: 3,
                              marginLeft: 3,
                            }}
                          />
                        }
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Container>
          )) || (
            <VerticalTimeline layout="1-column-left">
              {items.map((item, index) => (
                <VerticalTimelineElement
                  key={item.title}
                  date={item.date}
                  iconStyle={{
                    color: "#fff",
                    border: "none !important",
                    background: "white",
                  }}
                  icon={
                    <i
                      className={`${
                        (item.isDone && "fa fa-check") || item.icon
                      } icon icon-shape icon-shape-${
                        (item.isDone && "info") || item.variant
                      } ml--1 mt--1`}
                      style={{
                        border: "4px solid white",
                      }}
                    />
                  }
                >
                  {isEditing && (
                    <CardHeader className="d-flex justify-content-end">
                      <>
                        <Button>
                          <i className="fa fa-arrow-up" />
                        </Button>
                        <EditButton
                          title="Update roadmap"
                          type="roadmap_item"
                          value={item}
                          onConfirm={(value) => {
                            service.updateRoadmap(value);
                            updateAndPushRoadmapContent(
                              items.map(
                                (item, i) => (i === index && value) || item
                              )
                            );
                          }}
                        />
                        <DeleteButton
                          onConfirm={() => {
                            service.deleteRoadmap(item);
                            updateAndPushRoadmapContent(
                              items.filter((_, i) => i !== index)
                            );
                          }}
                        />
                      </>
                    </CardHeader>
                  )}
                  <h3 className="vertical-timeline-element-title">
                    {item.title}
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    {item.subtitle}
                  </h4>
                  <p>{item.description}</p>
                </VerticalTimelineElement>
              ))}

              <VerticalTimelineElement
                iconStyle={{
                  background: "rgb(16, 204, 82)",
                  color: "#fff",
                }}
                icon={
                  <i
                    className="fa fa-star fa-2x"
                    style={{
                      marginTop: 3,
                      marginLeft: 3,
                    }}
                  />
                }
              />
            </VerticalTimeline>
          )}
        </section>
      </main>

      <CardsFooter />
    </>
  );
};

export default Homepage;
