import { useEffect } from "react";

interface Props {
  isLoading: boolean;
}

const Loader = ({ isLoading }: Props) => {
  useEffect(() => {
    document.body.style.overflow = (isLoading && "hidden") || "auto";
  }, [isLoading]);
  if (!isLoading) {
    return <></>;
  }
  return (
    <div className="background-loader">
      <div className="loader" />
    </div>
  );
};

export default Loader;
